<template>
  <div class="align window-height-about">
    <div class="section-title fade" v-scrollanimation>
      <h2 class="section-header">About us</h2>
      <!-- <p class="section-paragraph">We strive to help you see through the new lenses</p> -->
    </div>

    <div class="description moveUp" v-scrollanimation>
      <div class="items">
        <ul style="padding: 0">
          <li v-for="(item, index) in items" :key="index">
            <q-icon :name="mdiFire" class="icons" />
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="content">
        <p>
          iThermAI is an AI company. Our main product is the smart fire and
          smoke detection (FSD) based on the surveillance camera video streams.
          Our mission is to provide smoke and fire detection cameras to the
          general public, for a good cause: every disaster which is prevented is
          our success! We provide our algorithms in cameras and embedded boards.
          Our core could be integrated in your fire panels. Please contact us if
          you need more information.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mdiFire } from "@quasar/extras/mdi-v6";

export default {
  setup() {
    const items = ref([
      "Fire and Smoke Detection",
      "Artificial Intelligence",
      "On-the-Edge Processing",
      "Smart Camera",
    ]);

    return {
      items,
      mdiFire,
    };
  },
};
</script>

<style scoped>
@import "../../styles/globalStyles.scss";

ul {
  list-style-type: none;
}
.icons {
  font-size: 35px;
  color: rgb(255, 136, 0);
  padding-right: 7px;
  vertical-align: top;
}
.description {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
}
.description .items {
  flex: 3;
  line-height: 25pt;
}
.description .content {
  flex: 4;
}

@media all and (max-width: 1024px) {
  .description {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .description {
    text-align: center;
  }
}
</style>
